<template>
    <div class="box flex" v-if="dialogTableVisible">
        <el-dialog :title="dialogTitle ? dialogTitle : '编辑'" :append-to-body="true" :width="width"
            :before-close="gbDialog" :visible.sync="dialogTableVisible" center>
            <!-- 编辑 -->
            <div class="dialog" v-if="bgShow">
                <div class="dialog_left">
                    <div class="dialog_left_groupBg">
                        群组名称:
                    </div>
                    <div class="dialog_left_groupBg" style="height: 410px;line-height: 410px;">
                        选择用户:
                    </div>
                </div>
                <div class="dialog_right">
                    <div class="dialog_group">
                        <div class="dialog_group_top flex block">
                            <div class="dialog_group_input">
                                <el-input maxlength="15" v-model.trim="fenceName" @input="bindInput"
                                    placeholder="请输入群组名称"></el-input>
                            </div>
                            <div class="dialog_group_red" v-if="isText">(<span
                                    style="color: red;padding: 0px 5px;">*由2-15位数字,中文,英文组成</span>)</div>
                        </div>
                        <!-- 开始 -->
                        <div class="dialog_group_user flex_between">
                            <div class="dialog_group_user_xz block" style="padding:0px 10px 0px 10px;">
                                <div class="dialog_group_user_xz_box" style="margin-right:25px;">
                                    <div class="dialog_group_user_xz_box_title">固定在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="dialog_group_user_search_tree" style="height: calc(100% - 15px);">
                                            <div class="enclosureUser mouse" v-for="(item, index) in temporaryUserArray"
                                                :key="item.User_ID">
                                                <img @click="deleteUserOne(index)" style="margin-right: 5px;"
                                                    src="../../assets/group/gb.png" alt="">
                                                {{ item.nickname }} ( {{ item.User_Account }} )
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">不在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="InGroupUserName" placeholder="用户/IMEI">
                                                </el-input>
                                                <img class="mouse" src="../../assets/group/gb.png" @click="bindEmpty(1)"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(1)">
                                                <img src="../../assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <!-- :render-content="renderContent" -->
                                            <el-tree @check="checkOne" ref="treeOne" :data="noGroupUserArray"
                                                show-checkbox node-key="User_ID" default-expand-all
                                                :expand-on-click-node="false">
                                                <div class="custom-tree-node ellipsis_app" slot-scope="{data}">
                                                    <span>{{ data.nickname }} ({{ data.User_Account }})</span>
                                                </div>
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 结束 -->
                        <!-- 开始 -->
                        <div class="dialog_group_user flex_between">
                            <div class="dialog_group_user_xz block" style="padding:0px 10px 10px 10px;">
                                <div class="dialog_group_user_xz_box" style="margin-right:25px;">
                                    <div class="dialog_group_user_xz_box_title">临时在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="dialog_group_user_search_tree" style="height: calc(100% - 15px);">
                                            <div class="enclosureUser mouse" v-for="(item, index) in InGroupUserArray"
                                                :key="item.User_ID">
                                                <img @click="deleteUser(index)" style="margin-right: 5px;"
                                                    src="../../assets/group/gb.png" alt="">
                                                {{ item.nickname }} ({{ item.User_Account }})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dialog_group_user_xz_box">
                                    <div class="dialog_group_user_xz_box_title">不在组用户</div>
                                    <div class="dialog_group_user_search">
                                        <!-- 搜索 -->
                                        <div class="search search_one flex">
                                            <div class="search_input flex">
                                                <!-- <el-input v-model="search" placeholder="群组/用户"></el-input> -->
                                                <!-- reserve-keyword -->
                                                <el-input v-model="noGroupUserName" placeholder="用户/IMEI">
                                                </el-input>
                                                <img class="mouse" src="../../assets/group/gb.png" @click="bindEmpty(2)"
                                                    alt="" />
                                            </div>
                                            <div class="search_but mouse" @click="bindSearch(2)">
                                                <img src="../../assets/group/search.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="dialog_group_user_search_tree">
                                            <!-- :render-content="renderContent" -->
                                            <el-tree @check="checkTow" ref="tree" :data="noGroupUserArrayTow"
                                                show-checkbox node-key="User_ID" default-expand-all
                                                :expand-on-click-node="false">
                                                <div class="custom-tree-node ellipsis_app" slot-scope="{data}">
                                                    <span>{{ data.nickname }} ({{ data.User_Account }})</span>
                                                </div>
                                            </el-tree>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 结束 -->
                    </div>
                </div>
            </div>
            <!-- 编辑结束 -->
            <div class="bottom">
                <el-button class="bc" @click="getSendOut()">{{ efId > 0 ? '保存' : '创建' }} </el-button>
                <el-button class="gb" @click="gbDialog()">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EventBus from "@/eventBus/eventBus.js";
import regfunc from "@/method/form/regfunc.js";
import axios from "axios";
import qs from "qs";
import beasconfig from '@/api/beasconfig.js';
import { queryUserByRegion } from "@/administration/dispatchLntercom.js";
// import { _debounce } from "@/method/utils.js";
const regionUrlapi = beasconfig.regionUrlapi;
export default {
    props: {
        // dialogTableVisible: {       //整个弹出框是否显示
        //     type: Boolean,
        //     default: true
        // },
        overlays: {       //对话框数据
            type: Array,
            default: () => ([])
        },
        dialogTitle: {
            type: String,
            default: '创建区域群组'
        },
        timeShow: {      //选择时间
            type: Boolean,
            default: false
        },
        width: {     //弹出框的宽
            type: String,
            default: '825px'
        },
        efId: {
            type: Number,
            default: 0
        },
        regionType: {
            type: Number,
            default: 0
        },
        bgShow: {    //编辑显示
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            userInfo: {},
            dialogTableVisible: true,
            groupName: '',        //群组名称
            InGroupUserArray: [],    //固定再组用户
            temporaryUserArray: [],   //临时群组用户
            InGroupUserName: '',    //在组围栏用户名称
            noGroupUserArray: [],    //临时在组用户
            noGroupUserArrayTow: [],
            noGroupUserName: '',     //不在组围栏用户名称
            fenceName: '',//围栏名称
            validStartTime: 0,
            validEndTime: 0,
            effectiveTimes: '',
            validPeriod: [new Date(2016, 9, 10), new Date(2016, 9, 10, 23, 59, 59)],//有效时段
            dayOfTheWeek: [2, 3, 4, 5, 6, 7, 1],
            effectiveDate: '',//有效日期
            prohibit: "1",      //禁止
            pointList: [],
            efRemark: '',     //1是自定义  2是区域
            isText: true
        };
    },
    created() {
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getQueryUserByRegion();
    },
    beforeCreate: function () { },
    methods: {
        bindInput(value) {        //不能输入
            this.fenceName = regfunc.noCharacter(value);
        },
        /*围栏详情*/
        getQueryUserByRegion(type) {     //搜索不再组成员
            let that = this;
            let keyword = '';
            if (type == 1) {
                keyword = that.InGroupUserName;
            }
            if (type == 2) {
                keyword = that.noGroupUserName;
            }
            let data = {
                uId: that.userInfo.id,
                keyword: keyword
            }
            queryUserByRegion(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    if (type == 1) {
                        that.noGroupUserArray = res.data.data;
                        let array = that.temporaryUserArray.map(item => item.User_ID);
                        that.$refs.treeOne.setCheckedKeys(array);
                    } else if (type == 2) {
                        that.noGroupUserArrayTow = res.data.data;
                        let array = that.InGroupUserArray.map(item => item.User_ID);
                        that.$refs.tree.setCheckedKeys(array);
                    } else {
                        that.noGroupUserArray = res.data.data;
                        that.noGroupUserArrayTow = res.data.data;
                    }
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            })
        },
        bindSearch(type) {      //搜索
            if (type == 1 && this.InGroupUserName == "") {

                return
            }
            if (type == 2 && this.noGroupUserName == "") {
                return
            }
            this.getQueryUserByRegion(type);
        },
        bindEmpty(type) {        //清空输入框值
            if (type == 1) {
                this.InGroupUserName = ""
            }
            if (type == 2) {
                this.noGroupUserName = "";
            }
            this.getQueryUserByRegion(type);
        },
        checkOne(data) {        //固定在组选中
            let index = this.temporaryUserArray.findIndex(item => data.User_ID == item.User_ID);
            let bool = this.InGroupUserArray.some(item => data.User_ID == item.User_ID);
            let num = this.temporaryUserArray.length + this.InGroupUserArray.length;
            if (bool) {
                this.$MessageWarning('此成员用户已在临时群组！');
                let array = this.temporaryUserArray.map(item => item.User_ID);
                this.$refs.treeOne.setCheckedKeys(array);
                return
            }
            if (index >= 0) {
                this.temporaryUserArray.splice(index, 1);
            } else {
                if (num >= 300) {
                    let array = this.temporaryUserArray.map(item => item.User_ID);
                    this.$refs.treeOne.setCheckedKeys(array);
                    this.$MessageWarning('群组添加成员数量超过上限!');
                    return
                }
                this.temporaryUserArray.push(data)
            }
        },
        checkTow(data) {     //临时再组选中
            let index = this.InGroupUserArray.findIndex(item => data.User_ID == item.User_ID);
            let bool = this.temporaryUserArray.some(item => data.User_ID == item.User_ID);
            let num = this.temporaryUserArray.length + this.InGroupUserArray.length;
            if (bool) {
                this.$MessageWarning('此成员用户已在固定群组！');
                let array = this.InGroupUserArray.map(item => item.User_ID);
                this.$refs.tree.setCheckedKeys(array);
                return
            }
            if (index >= 0) {
                this.InGroupUserArray.splice(index, 1);
            } else {
                if (num >= 300) {
                    let array = this.InGroupUserArray.map(item => item.User_ID);
                    this.$refs.tree.setCheckedKeys(array);
                    this.$MessageWarning('群组添加成员数量超过上限!');
                    return
                }
                this.InGroupUserArray.push(data)
            }
        },
        deleteUserOne(index) {
            this.temporaryUserArray.splice(index, 1);
            let array = this.temporaryUserArray.map(item => item.User_ID);
            this.$refs.treeOne.setCheckedKeys(array);
        },
        deleteUser(index) {       //删除用户
            this.InGroupUserArray.splice(index, 1);
            let array = this.InGroupUserArray.map(item => item.User_ID);
            this.$refs.tree.setCheckedKeys(array);
        },
        // 打开对话框
        openPg() {
            this.dialogTableVisible = true;
        },
        // 关闭对话框
        gbDialog() {
            this.dialogTableVisible = false;
            // this.$emit('clearOverwrites');
            EventBus.$emit('emptyMap', 1);
        },
        getSendOut() {       //发送
            let that = this;
            let bool = that.$store.state.groupUserData.some(item => item.label == that.fenceName);
            if(that.$store.state.recordAddRegionNum >= 200){
                that.$MessageWarning('最多只能创建200个区域群组!');
                return
            }
            if (!regfunc.rGroupName.test(that.fenceName)) {
                that.$MessageWarning('*群组名称由2-15位数字,中文,英文组成！');
                return
            }
            if (bool) {
                that.$MessageWarning('群组名称已经存在!');
                return
            }
            let array = this.overlays;
            let pointList = '';
            // let periodType = 0;
            if (array && that.$store.state.efRemark == 1) {
                // this.coordinateCalculationArea(array);
                that.pointList = '';
                for (var i = 0; i < array.length; i++) {
                    var lat = array[i].lat;
                    var lng = array[i].lng;
                    if (pointList == null || pointList == "") {
                        pointList = lng + "," + lat;
                    } else {
                        pointList = pointList + "|" + lng + "," + lat;
                    }
                }
                /*经纬度*/
                that.pointList = pointList;
            } else {
                that.pointList = array.join(";");
            }
            let timezoneOffset = -(new Date().getTimezoneOffset()); //时区
            let parmas = {
                name: that.fenceName,
                pointType: 0,//地图类型
                regionType: that.$store.state.efRemark,//类型 1自定义 2区域
                dspId: parseInt(that.userInfo.User_ID),// 调度员id
                timeOffset: parseInt(timezoneOffset),//时区
                pointList: that.pointList//经纬度数据
            };
            if (that.temporaryUserArray.length > 0) {
                parmas.fixUsers = that.temporaryUserArray.map(item => item.User_ID).join();
            }
            if (that.InGroupUserArray.length > 0) {
                parmas.tmpUsers = that.InGroupUserArray.map(item => item.User_ID).join();
            }
            // console.log(parmas)
            if (that.efId > 0) {
                parmas.efId = parseInt(that.efId) //围栏id
            }
            axios.post(`${regionUrlapi}-${this.$store.state.userState.server}/region/add`, qs.stringify(parmas),
                {
                    headers: beasconfig.tokenSl
                })
                .then(res => {
                    if (res.data.code == 200) {
                        that.dialogTableVisible = false;
                        that.$store.state.toUpdateEnclosure = 1;
                        that.$store.state.recordAddRegionNum+=1; 
                        console.log(that.$store.state.recordAddRegionNum)
                        that.$MessageSuccess('创建成功');
                        EventBus.$emit('emptyMap', 1);
                    } else if (res.data.code == 416) {
                        that.$MessageWarning('群组添加成员数量超过上限!');
                    } else {
                        that.$MessageWarning(res.data.message);
                    }
                })
        },
        deweight(arr) {      //数组去重
            let de_arr = []
            arr.forEach(i => {
                let isTrue = de_arr.every((j) => j.id != i.id);
                isTrue ? de_arr.push(i) : ''
            });
            return de_arr;
        }
    },
    mounted() {

    },
    filters: {

    },
    watch: {
        fenceName(value) {
            if (value && value != '') {
                this.isText = false;
            } else {
                this.isText = true;
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
::-webkit-scrollbar {
    width: 6px;
    height: 100%;
    background-color: #ccc;
}

::-webkit-scrollbar-track {
    width: 6px;
    background-color: #4D4D4D;
}

::-webkit-scrollbar-thumb {
    background-color: #727171;
}

.box {
    width: 100vw;
    height: 100vh;
    color: #fff;
}

/deep/.el-dialog {
    background: #0F3343;
    margin-top: 20vh;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4AFFFF !important;
    }

    .dialog {
        width: 100%;
        // background-color: #383838;
        margin: auto;
        color: #fff;
        // border: 1px solid #6e6d6d;
        display: flex;

        &_block {
            flex: 1;
            text-align: center;
            // height: 80px;
            // .demonstration {
            //     margin-bottom: 20px;
            // }

            .el-input--prefix .el-input__inner {
                padding-left: 15px;
            }

            // .el-icon-date:before {
            //     display: none;
            // }

            .el-range-separator {
                color: #fff;
            }

            .el-date-editor .el-range-input {
                color: #fff;
                background-color: transparent;
            }

            .el-range-editor .el-range-input {
                width: 100%;
            }

            // .el-date-editor .el-input__icon {
            //     display: none;
            // }

            .el-input__inner {
                width: 330px;
                height: 35px;
                background-color: transparent;
                border: 1px solid #666666;
                color: #fff;
                margin-top: 10px;
            }

            /deep/.el-checkbox__inner::after {
                border: 1px solid #000000;
            }
        }

        &_text {
            font-size: 14px;
            height: 65px;
            padding: 10px 0px;

        }

        &_left {
            width: 90px;
            height: 100%;
            background-color: #0F3343;

            &_groupBg {
                width: 100%;
                height: 45px;
                line-height: 45px;
                text-align: center;
            }

            &_bg {
                width: 100%;
                height: 120px;
                line-height: 120px;
                text-align: center;
            }

            &_bgOne {
                background-color: #383838;
            }
        }

        &_right {
            flex: 1;
            height: 100%;
            // margin: auto;
            margin-right: 20px;

            &_top {
                width: 90%;
                margin: 15px auto 25px auto;
                height: 340px;
                overflow: auto;
                border: 1px solid #6e6d6d;

                &_dh {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 97%;
                    margin: 15px auto;

                    &_left {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 6px 8px 6px 0px;
                        border-right-color: #2e7777;
                    }

                    &_right {
                        // flex: 1;
                        width: 80%;
                        min-height: 75px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        &_text {
                            padding: 15px 10px;
                            line-height: 25px;
                        }
                    }

                    &_rightOne {
                        width: 149px;
                        min-height: 55px;
                        background-color: #2e7777;
                        border-radius: 10px;
                        font-size: 14px;

                        img {
                            width: 15px;
                            height: 21px;
                            margin-left: 15px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }

                    &_text {
                        // position: absolute;
                        // left: calc(80% + 16px);
                        // bottom: 0px;
                        color: #727171;
                        margin-top: 35px;
                        margin-left: 10px;
                    }
                }
            }

            &_bottom {
                width: 90%;
                margin: auto;
                height: 85px;
                border: 1px solid #6e6d6d;

                .el-textarea__inner {
                    // width: 100%;
                    padding: 15px !important;
                    // height: 85px;
                    background-color: transparent;
                    color: #fff;
                    border: none;
                    font-size: 14px;
                    resize: none;
                }
            }

            &_ly {
                width: 90%;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_img {
                width: 302px;
                height: 66px;
                // margin: auto;
                background: url("../../assets/gb/lyaj.png");
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_delet {
                width: 88px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: #4AFFFF;
                margin-left: 35px;
            }
        }

        // 群组编辑
        &_group {
            width: 100%;
            margin: auto;

            &_top {
                width: 100%;
                height: 45px;
                margin-bottom: 20px;
                // border-bottom: 1px solid #6e6d6d;

                .el-input__inner {
                    width: 100%;
                    height: 40px;
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    border-radius: 0px !important;
                }
            }

            &_red {
                font-size: 12px;
                position: absolute;
                color: #cccccc;
                left: 210px;
            }

            &_input {
                width: 100%;
                // width: 285px;
                height: 40px;
                // margin: 0px 10px;
                position: relative;
            }

            &_user {
                width: 100%;
                height: 100%;

                // height: 370px;
                &_text {
                    margin-right: 15px;
                }

                &_xz {
                    // flex: 1;
                    width: 100%;
                    display: flex;

                    &_box {
                        // flex: 1;
                        width: 50%;
                        // width: 280px;
                        // height: 215px;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }

                &_but {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    img {
                        width: 23px;
                        height: 19px;
                        margin-top: 25px;
                    }
                }

                &_search {
                    width: 100%;
                    height: 180px;
                    border: 1px solid #6e6d6d;
                    overflow: hidden;

                    &_tree {
                        width: 100%;
                        height: calc(100% - 45px);
                        overflow: auto;
                        margin-top: 10px;
                    }
                }

                &_gl {
                    flex: 1;
                    display: flex;
                    margin-top: 20px;

                    &_box {
                        width: 215px;
                        text-align: center;

                        &_title {
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            text-align: center;
                            color: #4AFFFF;
                        }

                        &_content {
                            width: 100%;
                            height: 22px;
                            line-height: 22px;
                        }

                        &_contentOne {
                            border: 1px solid #6e6d6d;
                            border-radius: 2px;
                        }
                    }
                }
            }

            &_delet {
                width: 108px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                background-color: #2e7777;
                opacity: 0.9;
                border: 1px solid #30aeae;
                color: #4affff;
                margin-left: 10px;
            }
        }
    }

}

.dialog_group_user_gl_box_content /deep/.el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: center;
}

.dialog_group_user_gl_box_content /deep/.el-select .el-input .el-select__caret {
    color: #4AFFFF;
}

.dialog_group_user_gl_box_content /deep/.el-input__icon {
    line-height: 25px;
}

.search_one {
    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 28px;
        line-height: 28px;
        background-color: #333333;
        color: #ffffff;
        border: none;
        border-radius: 0px;
        padding: 0px 10px;
    }
}

.search_tow {
    width: 415px;
    height: 45px;
    border: 1px solid #2e7777;
    background-color: #383838 !important;
    border-radius: 2px;

    /deep/.el-input__inner {
        width: 100%;
        // width: 100%;
        height: 45px !important;
        line-height: 45px !important;
        background-color: #383838 !important;
        color: #ffffff;
        border: none !important;
        border-radius: 0px;
        padding: 0px 10px;
    }

    &_input {
        flex: 1;
        height: 100%;
        background-color: #383838;
        color: #ffffff !important;

        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 55px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}

.search {
    width: 100%;
    height: 28px;
    background-color: #3b7777;
    border: 1px solid #3b7777;

    &_input {
        flex: 1;
        height: 100%;
        background-color: #333333;
        color: #ffffff !important;


        img {
            width: 12px;
            height: 12px;
            margin: 0px 15px;
        }
    }

    &_but {
        width: 40px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background-color: #3b7777;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 17px;
            height: 17px;
        }
    }
}


.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #4AFFFF !important;
        background-color: transparent !important;
        color: #4AFFFF !important;
        margin-right: 30px;
    }
}


/deep/.el-tree {
    background-color: transparent;
    color: #fff;

    .el-tree-node__content:hover,
    .el-upload-list__item:hover {
        background-color: transparent !important;
    }

    .el-tree-node:focus,
    .is-expanded:focus,
    .is-focusable:focus {
        background-color: transparent !important;
    }

    .el-tree-node.is-current>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__expand-icon.expanded {
        color: #4affff;
    }

    .el-checkbox__inner {
        background-color: #343434 !important;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__inner:hover {
        border-color: #dcdfe6;
    }

    .el-checkbox__inner::after {
        border-color: #4affff;
    }

    .el-tree-node:focus>.el-tree-node__content {
        background: transparent !important;
    }

    .el-tree-node__content>.el-tree-node__expand-icon {
        padding: 0px;
    }

    .el-tree-node__content {
        line-height: 28px;
    }

    .el-tree-node__label {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

.el-checkbox__inner::after {
    color: #000;
}

/deep/.el-radio {
    color: #fff;

    .el-radio__input.is-checked .el-radio__inner {
        border-color: #ffffff;
        background: #000000;
    }

    .el-radio__input.is-checked+.el-radio__label {
        color: #fff;
    }

    .el-radio__inner::after {
        width: 8px;
        height: 8px;
        background-color: #4affff;
    }
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4AFFFF;
    border-color: #ffffff;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #fff;
}

.block {
    background-color: #08222E;
}

.enclosureUser {
    width: 94%;
    height: 28px;
    line-height: 28px;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex {
    display: flex;
    align-items: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_direction {
    //垂直居中
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.custom-tree-node {
    width: calc(100% - 50px);
}
</style>